import ReactPlayerLoader from '@brightcove/react-player-loader'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import styles from './video.module.scss'

import Seo from '@/components/general/seo'
import Layout from '@/components/layout/layout'

import { Navigation } from '@/components/video/navigation'
import ViewingEnv from '@/components/video/viewing-env'
import { ACCOUNT_ID } from '@/lib/constants'
import { BreadcrumbContext } from '@/types/breadcrumb'

const Page: React.FC<BreadcrumbContext> = ({ pageContext }) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext
  return (
    <Layout>
      <Seo title="双極I型障害における認知障害の薬理学的治療" />
      <Helmet>
        {/* <!-- Ptengine Tag --> */}
        <script src="https://js.ptengine.jp/67a5keif.js"></script>
        {/* End Ptengine Tag */}
      </Helmet>
      <div className={styles.wrap}>
        <h1 className={styles.title}>
          第9回 双極I型障害における認知障害の薬理学的治療
        </h1>
        <ReactPlayerLoader
          attrs={{ className: styles.video }}
          accountId={ACCOUNT_ID}
          videoId="6316087001112"
          playerId="hM1SBfBel"
          embedOptions={{ responsive: 'aspectRatio' }}
        />
        <div className={styles.videoBody}>
          <div className={styles.speaker}>
            <p>
              <span>ファシリテーター</span>
              <span>Eduard Vieta教授(バルセロナ大学)</span>
            </p>
            <p>
              <span>エキスパート</span>
              <span>Michael Berk教授 (ディーキン大学)</span>
            </p>
          </div>
          <Navigation prev="9" next="11" />
          <div className={styles.paragraph}>
            <p>
              双極I型障害の認知障害に対する薬物療法について、有効性と安全性を評価し、臨床試験の知見を活かして実際の患者に薬物療法を行う方法を考えます。また、認知機能障害が起こるメカニズムより、疾患の早期から介入することの重要性を学ぶことができます。
            </p>
            <p>大日本住友製薬（現：住友ファーマ株式会社）</p>
          </div>
          <ViewingEnv />
          <Breadcrumb
            crumbs={crumbs}
            crumbSeparator=" > "
            crumbLabel={'双極I型障害における認知障害の薬理学的治療'}
          />
        </div>
      </div>
    </Layout>
  )
}

export default Page
